<template>
  <div class="">
    <v-app class="">
      <v-row>
        <v-col class="px-0" cols="12" style="padding-bottom: 250px;">
          <!-- lg="8" md="8" offset-lg="2" offset-md="2" -->
          <v-form @submit.prevent="isLoading ? null : auth_request(formData)" class="mt-10">
            <v-container>
              <v-row :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-4' : null">
                <v-col cols="12" class="text-center py-3"></v-col>
                <v-col cols="12" class="text-center">
                  <h3 class="text-new-primary">Great to have you!</h3>
                  <p style="font-size: 17px;">Request a demo by entering information below</p>
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                  <v-text-field :disabled="isAuth" v-model="formData.name" label="Contact name" outlined
                    placeholder="Enter your full name"></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                  <v-text-field :disabled="isAuth" v-model="formData.organization" label="Organization name" outlined
                    placeholder="Enter your organization's name"></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                  <v-text-field :disabled="isAuth" v-model="formData.email" label="Email address" outlined
                    placeholder="Enter your email address"></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                  <v-text-field :disabled="isAuth" v-model="formData.phone" label="Phone number" outlined
                    placeholder="Enter your contact number"></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                  <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition"
                    offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field label="Schedule demo" outlined prepend-inner-icon="mdi-calendar-month-outline"
                        v-model="formData.date" placeholder="Select demo date" v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker color="#069" ref="picker" v-model="formData.date" :min="
                      new Date(new Date().getTime())
                        .toISOString()
                        .substring(0, 10)
                    " :max="new Date(new Date().getTime() + 3.156e+10)
.toISOString()
.substring(0, 10)" @change="save"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                  <v-select
                    :items="[
                        'Oil & Gas', 
                        'Law', 
                        'Real Estate', 
                        'Education', 
                        'Agriculture', 
                        'Technology', 
                        'Marketing', 
                        'Production', 
                        'Finance', 
                        'SME',
                        'HR',
                        'Investment',
                        'Research',
                        'Entrepreneurship'
                    ]"
                    label="Industry *" placeholder="Select your industry" outlined
                    v-model="formData.source"></v-select>
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                  <v-text-field :disabled="isAuth" v-model="formData.company_size" label="Company size" type="number"
                    outlined placeholder="Enter your company size"></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                  <v-select :items="servicesNames" label="Product focus"
                    placeholder="Select services you're interested in" multiple outlined v-model="formData.services">
                  </v-select>
                </v-col>

                <v-col cols="12" md="12" class="pb-0">
                  <v-textarea v-model="formData.message" label="Message" outlined placeholder="Message"></v-textarea>
                </v-col>

                <v-col class="pb-6 text-center" cols="12" sm="12">
                  <v-btn @click.prevent="isLoading ? null : request_demo()" type="submit" x-large dark
                    class="new-create-submit"
                    :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'w-100' : 'w-50'">
                    {{ btnTxt }}
                    <v-icon :style="!isLoading ? 'display: none' : ''">fas fa-circle-notch fa-spin</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-col>
      </v-row>

      <div class="text-center">
        <v-dialog v-model="dialog" width="500">
          <v-card>
            <v-card-text class="pt-5">
              <div class="col-md-8 mx-auto text-center pb-5">
                <img src="@/assets/graphics/check.png" alt="" class="img-fluid" />
              </div>
              <h3 class="msg pl-5 pr-5 text-center" style="display: block;">
                {{ responseForDialog }}
              </h3>
            </v-card-text>

            <v-divider class="mb-0"></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="#069" text @click="dialog = false">
                <strong>Close</strong>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <div class="text-center">
        <v-dialog v-model="dialog_2" width="500">
          <v-card>
            <v-card-text class="pt-5">
              <div class="col-md-8 mx-auto text-center pb-5">
                <img src="@/assets/graphics/cross-check.png" alt="" class="img-fluid" />
              </div>
              <h3 class="msg pl-5 pr-5 text-center" style="display: block;">
                {{ responseForDialog }}
              </h3>
            </v-card-text>

            <v-divider class="mb-0"></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="#069" text @click="dialog_2 = false">
                <strong>Close</strong>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>

    </v-app>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import axios from "axios";
import { BASE_URL } from "@/main";
export default {
  data() {
    return {
      menu: false,
      dialog: false,
      dialog_2: false,
      responseForDialog: "",
      formData: {
        name: "",
        organization: "",
        email: "",
        phone: "",
        date: "",
        source: "",
        company_size: "",
        message: "",
        services: []
      },
      btnTxt: "Submit",
      isLoading: false
    };
  },
  computed: {
    ...mapState([
        'captchaSiteKey'
    ]),
    ...mapGetters([
      'authCompanyData',
      'authUserData',
      'isAuth',
      'authAllAvailableServices'
    ]),
    servicesNames() {
      let _this = this, humanResourcesServices = [], humanResourcesServicesNames = [], accountsServices = [], accountsServicesNames = [], result = [];
      if (_this.authAllAvailableServices) {
        humanResourcesServices = _this.authAllAvailableServices.human_resources_services;
        accountsServices = _this.authAllAvailableServices.accounting_services;
        humanResourcesServicesNames = humanResourcesServices.map(service => service.service_name);
        accountsServicesNames = accountsServices.map(service => service.service_name);
        result = [...humanResourcesServicesNames, ...accountsServicesNames];
      }
      return result;
    },
    productFocus() {
      let _this = this, value = '';
      _this.formData.services.forEach((service, index) => {
        value += index === (_this.formData.services.length - 1) ? `${service}.` : `${service}, `
      });
      return value;
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    }
  },
  methods: {
    ...mapActions([
      'fetch_available_services'
    ]),
    save(date) {
      this.$refs.menu.save(date);
    },
    async request_demo() {
        let _this = this;
        await window.grecaptcha.ready(function () {
            if (_this.isLoading) {
                return;
            }
            _this.btnTxt = "";
            _this.isLoading = true;
            window.grecaptcha.execute(_this.captchaSiteKey, { action: 'submit' })
            .then(function (token) {
                axios.post(`${BASE_URL}demo`, {
                    contact_person: _this.formData.name,
                    organization: _this.formData.organization,
                    email: _this.formData.email,
                    phone: _this.formData.phone,
                    date: _this.formData.date,
                    source: _this.formData.source,
                    company_size: _this.formData.company_size,
                    message: _this.formData.message,
                    services: _this.productFocus,
                    token
                })
                .then(function () {
                    _this.responseForDialog = 'Your demo request has been sent and received successfully. You\'ll be contacted shortly'
                    _this.dialog = true;
                    if (_this.isAuth === false) {
                        _this.formData.name = '';
                        _this.formData.organization = '';
                        _this.formData.email = '';
                        _this.formData.phone = '';
                        _this.formData.company_size = '';
                    }
                    _this.formData.date = '';
                    _this.formData.source = '';
                    _this.formData.message = '';
                    _this.formData.services = [];
                    _this.isLoading = false;
                    _this.btnTxt = "Submit";
                })
                .catch(function () {
                    _this.isLoading = false;
                    _this.btnTxt = "Submit";
                    _this.responseForDialog = 'Your request couldn\'t be sent';
                    _this.dialog_2 = true;
                })
                .then(function () {
                    _this.isLoading = false;
                    _this.btnTxt = "Submit";
                });
            })
            .catch(function (error) {
                _this.isLoading = false;
                _this.btnTxt = "Submit";
                console.error('reCAPTCHA error:', error);
            });
        });
    },
  },
  mounted() {
    document.title = "Kylian ERP - Request Demo";
    this.fetch_available_services();
    if (this.isAuth) {
      this.formData.name = this.authUserData.name;
      this.formData.organization = this.authCompanyData.company_name;
      this.formData.email = this.authUserData.email;
      this.formData.phone = this.authCompanyData.phone;
      this.formData.company_size = this.authCompanyData.number_of_employees;
    }
  }
};
</script>

<style lang="scss">
$new-button-color: #225BA6;
$primary-color: #069;
$text-field-color: #4e4e52;

.text-new-primary {
  color: $new-button-color !important;
}

.v-application .primary--text {
  color: $primary-color !important;
}

.fa-eye,
.fa-eyeslash {
  color: $primary-color !important;
}

// .v-text-field__suffix, .v-text-field__prefix, input {
//     color: $text-field-color !important;
//     background-color: #00669914;
//     padding: 10px;
//     height: 48px;
// }
.v-text-field__suffix {
  border-radius: 0px 5px 5px 0px;
  padding-top: 13px;
  padding-left: 8px !important;
}

.v-text-field__prefix {
  border-radius: 5px 0px 0px 5px;
  padding-top: 13px;
  padding-right: 8px !important;
  text-decoration: underline;
}

.company-name .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) {
  label {
    margin-left: 2%;
  }

  input {
    text-indent: 3%;
  }
}

.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
  box-shadow: 0px 0px 50px 0px #00000014 !important;
}

.v-text-field__details {
  padding-left: 0 !important;
}

.theme--light.v-messages {
  color: $primary-color !important;
}

.company-name .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
  padding: 0px !important;
}

.remove-bottom-hint div .v-text-field__details {
  display: none;
}
</style>
<style lang="scss" scoped>
$new-button-color: #2081FF;
$primary-color: #069;

@media (max-width: 991px) {
  .create-submit {
    height: 45px !important;
  }

  .v-form {
    margin: 0 !important;
  }
}

.v-form {
  margin: 0 20%;
}

.svg-login {
  position: absolute;
  top: 8%;
  right: 22%;
}

#small-link {
  color: $primary-color !important;
}

.space-top {
  padding-top: 40px;
}

.create-submit {
  width: 100%;
  height: 60px;
  border-radius: 10px !important;
  background-color: #069 !important;
  text-transform: initial !important;
  font-weight: 600 !important;

  span {
    text-transform: initial !important;
  }
}

.new-create-submit {
  width: 100%;
  height: 60px;
  border-radius: 10px !important;
  background-color: $new-button-color !important;
  text-transform: initial !important;
  font-weight: 600 !important;

  span {
    text-transform: initial !important;
  }
}

h3 {
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.6252632141113281px;
  text-align: center;
  color: #111d5e;
}

label {
  color: #999 !important;
}
</style>
