<template>
  <v-app v-if="overlay">
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-app>
  <div v-else>
    <!-- <SideMenu /> -->
    <div class="main-content-wrap sidenav-open d-flex flex-column">
      <div class="main-header pl-4 pt-4" style="z-index: 60;">
        <div class="row animate__animated animate__fadeIn mb-3">
          <div style="align-self: center;" class="logo col-md-2 col-lg-2 col-3 py-0">
            <div class="dropdown animate__animated animate__fadeIn" style="font-size: 120%;">
              <i @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                class="fal fa-arrow-alt-circle-left mr-1"></i>
              <span class="ml-2" style="top: 4%; position: relative;">Back</span>
            </div>
          </div>
          <div class="input-group-prepend">
            <button class="btn dropdown-toggle w-100" type="button" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              <span>{{
    filter == 1
      ? "All leave requests"
      : filter == 2
        ? "Approved leave requests"
        : filter == 3
          ? "Unapproved leave requests"
          : filter == 4
            ? "Pending leave requests"
            : "All leave remaining"
  }}</span>
            </button>
            <div class="dropdown-menu" style="min-width: 13rem;">
              <router-link :to="{ name: 'LeaveApply' }" class="dropdown-item" href="#">
                Your leave data
              </router-link>
              <a @click="filter = 1" class="dropdown-item" href="#">
                All leave requests
              </a>
              <a @click="filter = 2" class="dropdown-item" href="#">
                Approved leave requests
              </a>
              <a @click="filter = 3" class="dropdown-item" href="#">
                Unapproved leave requests
              </a>
              <a @click="filter = 4" class="dropdown-item" href="#">
                Pending leave requests
              </a>
              <a @click="filter = 5" class="dropdown-item" href="#">
                All leave remaining
              </a>
            </div>
          </div>
        </div>
        <div style="margin: auto"></div>
        <div class="header-part-right">
          <!-- <div class="dropdown">
            <button
              class="btn ripple m-1"
              type="button"
              @click="refresh_page()"
              :class="refreshing_page ? 'rotate-spinner' : ''"
            >
              <span class="ul-btn__icon"
                ><i style="font-weight: bold;" class="i-Repeat-21"></i
              ></span>
            </button>
          </div> -->

          <div v-if="$vuetify.breakpoint.name != 'xs' &&
    $vuetify.breakpoint.name != 'sm'
    " class="dropdown">
            <v-row v-if="permissionIsAssigned('leave-read') && filter == 5" class="">
              <v-col offset-sm="4" offset-md="4" cols="12" sm="6" md="6" class="remove-hint pt-0 mb-1">
                <v-select class="remove-mb" :items="allEmployees" placeholder="Employee" dense solo
                  messages="*Select Employee" :full-width="true" v-model="employee_name"></v-select>
              </v-col>
            </v-row>
            <v-row v-if="permissionIsAssigned('leave-read') && filter != 5" class="">
              <v-col cols="6" sm="6" md="6" class="remove-hint pt-1">
                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field color="#069" prepend-inner-icon="mdi-calendar-month-outline" v-model="filtering.from"
                      placeholder="From" :disabled="false" dense solo readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker color="#069" ref="picker" v-model="filtering.from" :disabled="false" :max="new Date(new Date().getTime() + 1.577e10)
    .toISOString()
    .substring(0, 10)
    " @change="save"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" sm="6" md="6" class="remove-hint pt-1">
                <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition"
                  offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field color="#069" prepend-inner-icon="mdi-calendar-month-outline" v-model="filtering.to"
                      placeholder="To" :disabled="false" dense solo readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker color="#069" ref="picker2" v-model="filtering.to" :disabled="false" :max="new Date(new Date().getTime() + 1.577e10)
    .toISOString()
    .substring(0, 10)
    " @change="save2"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="2" md="2" class="remove-hint pt-1" style="align-self: center;">
                <button v-if="filtering.from && filtering.to" @click="(filtering.from = ''), (filtering.to = '')"
                  style="float: right;" type="button" class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                  Remove Filter
                </button>
              </v-col>
            </v-row>
          </div>
          <!-- Notificaiton End -->
          <!-- User avatar dropdown -->
        </div>
      </div>

      <!-- ======== Body content start ========= -->
      <div class="main-content" :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
    ? 'px-0'
    : null
    " style="margin-bottom: 100px;">
        <div v-if="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
    " class="dropdown">
          <v-row v-if="permissionIsAssigned('leave-read') && filter == 5" class="">
            <v-col offset-sm="4" offset-md="4" cols="12" sm="6" md="6" class="remove-hint pt-0 mb-1">
              <v-select class="remove-mb" :items="allEmployees" placeholder="Employee" dense solo
                messages="*Select Employee" :full-width="true" v-model="employee_name"></v-select>
            </v-col>
          </v-row>
          <v-row v-if="permissionIsAssigned('leave-read') && filter != 5" class="">
            <v-col cols="6" sm="6" md="6" class="remove-hint pt-2">
              <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
                min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field color="#069" prepend-inner-icon="mdi-calendar-month-outline" v-model="filtering.from"
                    placeholder="From" :disabled="false" dense solo readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker color="#069" ref="picker" v-model="filtering.from" :disabled="false" :max="new Date(new Date().getTime() + 1.577e10)
    .toISOString()
    .substr(0, 10)
    " @change="save"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" sm="6" md="6" class="remove-hint pt-2">
              <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y
                min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field color="#069" prepend-inner-icon="mdi-calendar-month-outline" v-model="filtering.to"
                    placeholder="To" :disabled="false" dense solo readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker color="#069" ref="picker2" v-model="filtering.to" :disabled="false" :max="new Date(new Date().getTime() + 1.577e10)
    .toISOString()
    .substr(0, 10)
    " @change="save2"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="2" md="2" class="remove-hint pt-2" style="align-self: center;">
              <button v-if="filtering.from && filtering.to" @click="(filtering.from = ''), (filtering.to = '')"
                style="float: right;" type="button" class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                Remove Filter
              </button>
            </v-col>
          </v-row>
        </div>
        <div :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
    ? 'mt-2'
    : null
    " class="card" style="border-radius: 10px;">
          <div class="card-body px-1 py-1 pt-0 pr-0">
            <div class="table-responsive"
              style="border-radius: 10px; height: 75vh; overflow-y: scroll; padding-top: 0; padding-bottom: 0;">
              <table v-if="filter == 5" class="table">
                <thead>
                  <tr>
                    <th class="text-left" scope="col">Employee Name</th>
                    <th class="text-left" scope="col">Leave Type</th>
                    <th class="text-left" scope="col">
                      Leave Type Description
                    </th>
                    <th class="text-left" scope="col">Days Taken</th>
                    <th class="text-left" scope="col">Days Left</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="leave in filteredLeaveLeft" :key="leave.id">
                    <td class="text-left">
                      {{ get_employee_name(leave.employee_id) }}
                    </td>
                    <td class="text-left">{{ leave.leave_name }}</td>
                    <td class="text-left">{{ leave.leave_description }}</td>
                    <td class="text-left">{{ leave.days_taken }}</td>
                    <td class="text-left">{{ leave.message }}</td>
                  </tr>
                </tbody>
              </table>
              <table v-else class="table">
                <thead>
                  <tr>
                    <th class="text-left" scope="col">Employee Name</th>
                    <th class="text-left" scope="col">Start Date</th>
                    <th class="text-left" scope="col">End Date</th>
                    <th class="text-left" scope="col">Leave Days Applied</th>
                    <th class="text-left" scope="col">Leave Days Left</th>
                    <th class="text-left" scope="col">Leave Type</th>
                    <th class="text-left" scope="col">Attached Document</th>
                    <th class="text-center" scope="col">Status</th>
                    <th class="text-left" scope="col">Purpose of Leave</th>
                    <th class="text-center" v-if="(filter == 1 || filter == 4) &&
    (permissionIsAssigned('leave-approve') || hasWorkflow('absence or leave'))
    " scope="col">
                      Actions
                    </th>
                    <th v-if="filter == 2 && (permissionIsAssigned('leave-approve') || hasWorkflow('absence or leave'))
    " class="text-center" scope="col" style="width:">
                      Disapprove
                    </th>
                    <th v-if="filter == 3 && (permissionIsAssigned('leave-approve') || hasWorkflow('absence or leave'))
    " class="text-center" scope="col" style="width:">
                      Approve
                    </th>
                  </tr>
                </thead>
                <tbody v-if="filter == 1">
                  <tr v-for="leave in filteredLeave" :key="leave.id">
                    <!-- <th scope="row" style="padding-left: 2.5%;">
                                    <label class="checkbox checkbox-secondary">
                                        <input type="checkbox" :checked="false">
                                        <span class="checkmark"></span>
                                    </label>
                                </th> -->
                    <td class="text-left">{{ leave.employee_name }}</td>
                    <td class="text-left">{{ leave.leave_start }}</td>
                    <td class="text-left">{{ leave.leave_end }}</td>
                    <td class="text-left">{{ getLeaveDaysApplied(leave.leave_start, leave.leave_end) +
    (getLeaveDaysApplied(leave.leave_start, leave.leave_end) == 1 ? ' day' : ' days') }}</td>
                    <td class="text-left">{{ getLeaveDaysLeft(leave.employee_id, leave.leave_type) }}</td>
                    <td class="text-left">{{ get_type_name(leave.leave_type) }}</td>
                    <td v-if="leave.file" class="text-left">
                      <a :href="assets_url + leave.file" target="_blank" rel="noopener noreferrer">
                        View Document
                      </a>
                    </td>
                    <td v-else class="text-left">
                      <i>No attached document</i>
                    </td>
                    <td class="text-center" v-if="leave.status == 0">
                      <span style="width: 60px;" class="badge badge-danger text-white">DENIED</span>
                    </td>
                    <td class="text-center" v-else-if="leave.status == 1">
                      <span style="width: 60px;" class="badge badge-success">APPROVED</span>
                    </td>
                    <td class="text-center" v-else>
                      <span style="width: 60px;" class="badge badge-warning text-white">PENDING</span>
                    </td>
                    <td class="text-left" v-if="leave.additional_comments">
                      {{ leave.additional_comments }}
                    </td>
                    <td class="text-left" v-else><i>No comment</i></td>
                    <td v-if="(permissionIsAssigned('leave-approve') || hasWorkflow('absence or leave'))"
                      style="padding-top: 25px;" class="employee-options text-left">
                      <div class="row">
                        <i v-if="leave.status != 1" @click="view_comment(leave)"
                          class="py-0 text-center fal fa-message-dots" :class="$vuetify.breakpoint.name == 'xs' ||
    $vuetify.breakpoint.name == 'sm'
    ? 'col-1'
    : 'offset-1 col-3'
    " style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                        <i v-else class="py-0 text-center fal fa-message-dots" :class="$vuetify.breakpoint.name == 'xs' ||
    $vuetify.breakpoint.name == 'sm'
    ? 'col-1'
    : 'offset-1 col-3'
    " style="opacity: 0.3; cursor: default; color: #069 !important; font-size: 125%;"></i>
                        <i :class="$vuetify.breakpoint.name == 'xs' ||
    $vuetify.breakpoint.name == 'sm'
    ? null
    : 'ml-2'
    " class="col-3 py-0 text-center fal fa-check-circle" @click="approve_single_leave(leave)"
                          style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                        <i :class="$vuetify.breakpoint.name == 'xs' ||
    $vuetify.breakpoint.name == 'sm'
    ? null
    : 'ml-2'
    " class="col-3 py-0 text-center fal fa-times-circle" @click="disapprove_single_leave(leave)"
                          style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else-if="filter == 2">
                  <tr v-for="leave in filteredLeave" :key="leave.id">
                    <!-- <th scope="row" style="padding-left: 2.5%;">
                                    <label class="checkbox checkbox-secondary">
                                        <input type="checkbox" :checked="false">
                                        <span class="checkmark"></span>
                                    </label>
                                </th> -->
                    <td class="text-left">{{ leave.employee_name }}</td>
                    <td class="text-left">{{ leave.leave_start }}</td>
                    <td class="text-left">{{ leave.leave_end }}</td>
                    <td class="text-left">{{ getLeaveDaysApplied(leave.leave_start, leave.leave_end) +
    (getLeaveDaysApplied(leave.leave_start, leave.leave_end) == 1 ? ' day' : ' days') }}</td>
                    <td class="text-left">{{ getLeaveDaysLeft(leave.employee_id, leave.leave_type) }}</td>
                    <td class="text-left">{{ get_type_name(leave.leave_type) }}</td>
                    <td v-if="leave.file" class="text-left">
                      <a :href="assets_url + leave.file" target="_blank" rel="noopener noreferrer">
                        View Document
                      </a>
                    </td>
                    <td v-else class="text-left">
                      <i>No attached document</i>
                    </td>
                    <td class="text-center" v-if="leave.status == 0">
                      <span style="width: 60px;" class="badge badge-danger text-white">DENIED</span>
                    </td>
                    <td class="text-center" v-else-if="leave.status == 1">
                      <span style="width: 60px;" class="badge badge-success">APPROVED</span>
                    </td>
                    <td class="text-center" v-else>
                      <span style="width: 60px;" class="badge badge-warning text-white">PENDING</span>
                    </td>
                    <td class="text-left" v-if="leave.additional_comments">
                      {{ leave.additional_comments }}
                    </td>
                    <td class="text-left" v-else><i>No comment</i></td>
                    <td v-if="(permissionIsAssigned('leave-approve') || hasWorkflow('absence or leave'))"
                      style="padding-top: 15px;" class="employee-options text-left">
                      <div class="text-center">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <i v-bind="attrs" v-on="on" class="py-0 text-center fal fa-times-circle"
                              @click="disapprove_single_leave(leave)"
                              style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                          </template>
                          <span>Deny request</span>
                        </v-tooltip>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else-if="filter == 3">
                  <tr v-for="leave in filteredLeave" :key="leave.id">
                    <!-- <th scope="row" style="padding-left: 2.5%;">
                                    <label class="checkbox checkbox-secondary">
                                        <input type="checkbox" :checked="false">
                                        <span class="checkmark"></span>
                                    </label>
                                </th> -->
                    <td class="text-left">{{ leave.employee_name }}</td>
                    <td class="text-left">{{ leave.leave_start }}</td>
                    <td class="text-left">{{ leave.leave_end }}</td>
                    <td class="text-left">{{ getLeaveDaysApplied(leave.leave_start, leave.leave_end) +
    (getLeaveDaysApplied(leave.leave_start, leave.leave_end) == 1 ? ' day' : ' days') }}</td>
                    <td class="text-left">{{ getLeaveDaysLeft(leave.employee_id, leave.leave_type) }}</td>
                    <td class="text-left">{{ get_type_name(leave.leave_type) }}</td>
                    <td v-if="leave.file" class="text-left">
                      <a :href="assets_url + leave.file" target="_blank" rel="noopener noreferrer">
                        View Document
                      </a>
                    </td>
                    <td v-else class="text-left">
                      <i>No attached document</i>
                    </td>
                    <td class="text-center" v-if="leave.status == 0">
                      <span style="width: 60px;" class="badge badge-danger text-white">DENIED</span>
                    </td>
                    <td class="text-center" v-else-if="leave.status == 1">
                      <span style="width: 60px;" class="badge badge-success">APPROVED</span>
                    </td>
                    <td class="text-center" v-else>
                      <span style="width: 60px;" class="badge badge-warning text-white">PENDING</span>
                    </td>
                    <td class="text-left" v-if="leave.additional_comments">
                      {{ leave.additional_comments }}
                    </td>
                    <td class="text-left" v-else><i>No comment</i></td>
                    <td v-if="(permissionIsAssigned('leave-approve') || hasWorkflow('absence or leave'))"
                      class="employee-options text-left">
                      <div class="text-center">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <i v-bind="attrs" v-on="on" class="py-0 text-center fal fa-check-circle"
                              @click="approve_single_leave(leave)"
                              style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                          </template>
                          <span>Approve request</span>
                        </v-tooltip>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else-if="filter == 4">
                  <tr v-for="leave in filteredLeave" :key="leave.id">
                    <!-- <th scope="row" style="padding-left: 2.5%;">
                                    <label class="checkbox checkbox-secondary">
                                        <input type="checkbox" :checked="false">
                                        <span class="checkmark"></span>
                                    </label>
                                </th> -->
                    <td class="text-left">{{ leave.employee_name }}</td>
                    <td class="text-left">{{ leave.leave_start }}</td>
                    <td class="text-left">{{ leave.leave_end }}</td>
                    <td class="text-left">{{ getLeaveDaysApplied(leave.leave_start, leave.leave_end) +
    (getLeaveDaysApplied(leave.leave_start, leave.leave_end) == 1 ? ' day' : ' days') }}</td>
                    <td class="text-left">{{ getLeaveDaysLeft(leave.employee_id, leave.leave_type) }}</td>
                    <td class="text-left">{{ get_type_name(leave.leave_type) }}</td>
                    <td v-if="leave.file" class="text-left">
                      <a :href="assets_url + leave.file" target="_blank" rel="noopener noreferrer">
                        View Document
                      </a>
                    </td>
                    <td v-else class="text-left">
                      <i>No attached document</i>
                    </td>
                    <td class="text-center" v-if="leave.status == 0">
                      <span style="width: 60px;" class="badge badge-danger text-white">DENIED</span>
                    </td>
                    <td class="text-center" v-else-if="leave.status == 1">
                      <span style="width: 60px;" class="badge badge-success">APPROVED</span>
                    </td>
                    <td class="text-center" v-else>
                      <span style="width: 60px;" class="badge badge-warning text-white">PENDING</span>
                    </td>
                    <td class="text-left" v-if="leave.additional_comments">
                      {{ leave.additional_comments }}
                    </td>
                    <td class="text-left" v-else><i>No comment</i></td>
                    <td v-if="(permissionIsAssigned('leave-approve') || hasWorkflow('absence or leave'))"
                      class="employee-options text-left">
                      <div class="row">
                        <div class="text-center col-6">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <i v-bind="attrs" v-on="on" class="py-0 text-center fal fa-check-circle"
                                @click="approve_single_leave(leave)"
                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                            </template>
                            <span>Approve request</span>
                          </v-tooltip>
                        </div>
                        <div class="text-center col-6">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <i v-bind="attrs" v-on="on" class="py-0 text-center fal fa-times-circle"
                                @click="disapprove_single_leave(leave)"
                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                            </template>
                            <span>Deny request</span>
                          </v-tooltip>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr v-for="leave in filteredLeave" :key="leave.id">
                    <!-- <th scope="row" style="padding-left: 2.5%;">
                                    <label class="checkbox checkbox-secondary">
                                        <input type="checkbox" :checked="false">
                                        <span class="checkmark"></span>
                                    </label>
                                </th> -->
                    <td class="text-left">{{ leave.employee_name }}</td>
                    <td class="text-left">{{ leave.leave_start }}</td>
                    <td class="text-left">{{ leave.leave_end }}</td>
                    <td class="text-left">{{ getLeaveDaysApplied(leave.leave_start, leave.leave_end) +
    (getLeaveDaysApplied(leave.leave_start, leave.leave_end) == 1 ? ' day' : ' days') }}</td>
                    <td class="text-left">{{ getLeaveDaysLeft(leave.employee_id, leave.leave_type) }}</td>
                    <td class="text-left">{{ get_type_name(leave.leave_type) }}</td>
                    <td v-if="leave.file" class="text-left">
                      <a :href="assets_url + leave.file" target="_blank" rel="noopener noreferrer">
                        View Document
                      </a>
                    </td>
                    <td v-else class="text-left">
                      <i>No attached document</i>
                    </td>
                    <td class="text-center" v-if="leave.status == 0">
                      <span style="width: 60px;" class="badge badge-danger text-white">DENIED</span>
                    </td>
                    <td class="text-center" v-else-if="leave.status == 1">
                      <span style="width: 60px;" class="badge badge-success">APPROVED</span>
                    </td>
                    <td class="text-center" v-else>
                      <span style="width: 60px;" class="badge badge-warning text-white">PENDING</span>
                    </td>
                    <td class="text-left" v-if="leave.additional_comments">
                      {{ leave.additional_comments }}
                    </td>
                    <td class="text-left" v-else><i>No comment</i></td>
                    <td v-if="(permissionIsAssigned('leave-approve') || hasWorkflow('absence or leave'))"
                      class="employee-options text-left">
                      <div class="row">
                        <div class="text-center col-6">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <i v-bind="attrs" v-on="on" class="py-0 text-center fal fa-check-circle"
                                @click="approve_single_leave(leave)"
                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                            </template>
                            <span>Approve request</span>
                          </v-tooltip>
                        </div>
                        <div class="text-center col-6">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <i v-bind="attrs" v-on="on" class="py-0 text-center fal fa-times-circle"
                                @click="disapprove_single_leave(leave)"
                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                            </template>
                            <span>Deny request</span>
                          </v-tooltip>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- Footer Start -->
      <div class="flex-grow-1"></div>
      <!-- fotter end -->
    </div>

    <v-dialog v-model="leave_comment_dialog" max-width="900px">
      <v-card>
        <v-card-title class="text-left d-block">
          <span class="headline">Leave Comments</span>
          <button @click="add_leave_comment_dialog = true" style="float: right;" type="button"
            class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
            Add Comment
          </button>
        </v-card-title>
        <v-card-text>
          <v-container>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th class="text-left">Date Sent</th>
                  <th class="text-left">Comment</th>
                  <th class="text-left">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="comment in all_leave_comments" :key="comment.id">
                  <td class="text-left">
                    {{
    new Date(comment.created_at)
      .toUTCString()
      .substring(0, 16)
  }}
                  </td>
                  <td class="text-left">
                    {{ comment.comments }}
                  </td>
                  <td v-if="current_leave.status != 1" style="padding-top: 13px;" class="employee-options">
                    <div class="row">
                      <i @click="
    (edit_leave_comment_dialog = true),
    (current_comment = comment),
    (comment3 = comment.comments)
    " class="col-3 py-4 text-center fal fa-edit mr-2"
                        style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                      <i @click="delete_comment(comment)" class="col-3 py-4 text-center fal fa-trash ml-2"
                        style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="leave_comment_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="comment_dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ approval ? "Approve" : "Reject" }} Leave with Comment</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref='form1' @submit.prevent>
              <div class="container-fluid">
                <v-row>
                  <v-col cols="12">
                    <v-textarea v-model="comment" :label="userType == 1 ||
    (userType != 1 && permissionIsAssigned('leave-read'))
    ? 'Leave Comment'
    : 'No comment'
    " solo></v-textarea>
                  </v-col>
                  <v-col v-if="userType == 1 ||
    (userType != 1 && permissionIsAssigned('leave-read'))
    " cols="12" sm="4" md="4" offset-md="4" offset-sm="4">
                    <button @click="add_comment_submit ? null : main_leave_action()" style="width: 99%;" type="button"
                      class="btn btn-secondary m-1 text-white btn-raised ripple">
                      {{ add_comment_text }}
                      <v-icon :style="!add_comment_submit
    ? 'display: none;'
    : 'color: #fff; padding: 0px 10px; font-size: 16px;'
    ">
                        fal fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="comment_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="add_leave_comment_dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Add Comment</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref='form2' @submit.prevent>
              <div class="container-fluid">
                <v-row>
                  <v-col cols="12">
                    <v-textarea :rules="[
    v => !!v || 'Comment is required',
    v => v.length >= 5 || 'Comment must be at least 5 characters long',
  ]" v-model="comment2" label="Enter comment" solo></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4">
                    <button @click="
    add_comment2_submit ? null : submit_leave_comment()
    " style="width: 99%;" type="button" class="btn btn-secondary m-1 text-white btn-raised ripple">
                      {{ add_comment2_text }}
                      <v-icon :style="!add_comment2_submit
    ? 'display: none;'
    : 'color: #fff; padding: 0px 10px; font-size: 16px;'
    ">
                        fal fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="add_leave_comment_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="edit_leave_comment_dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Add Comment</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref='form3' @submit.prevent>
              <div class="container-fluid">
                <v-row>
                  <v-col cols="12">
                    <v-textarea :rules="[
    v => !!v || 'Comment is required',
    v => v.length >= 5 || 'Comment must be at least 5 characters long',
  ]" v-model="comment3" label="Enter comment" solo></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4">
                    <button @click="
    add_comment3_submit
      ? null
      : update_leave_comment(current_comment)
    " style="width: 99%;" type="button" class="btn btn-secondary m-1 text-white btn-raised ripple">
                      {{ add_comment3_text }}
                      <v-icon :style="!add_comment3_submit
    ? 'display: none;'
    : 'color: #fff; padding: 0px 10px; font-size: 16px;'
    ">
                        fal fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="edit_leave_comment_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { ASSETS_URL, BASE_URL } from "@/main";
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    comment2: "",
    add_comment2_text: "Submit",
    add_comment2_submit: false,
    add_leave_comment_dialog: false,
    current_comment: "",
    comment3: "",
    add_comment3_text: "Update",
    add_comment3_submit: false,
    edit_leave_comment_dialog: false,
    allEmployees: [],
    employee_name: "",
    filtering: {
      from: "",
      to: ""
    },
    menu: false,
    menu2: false,
    overlay: true,
    approval: false,
    current_leave: null,
    comment_dialog: false,
    // all_leave_remaining: [],
    refreshing_page: false,
    create_leave_type_form: false,
    leave_type_to_create: "",
    createLeaveTypeText: "Create",
    createLeaveTypeSubmit: false,
    filter: 1,
    events: [
      {
        start: "2021-04-27",
        end: "2021-04-27",
        title: "Meeting by 2",
        content: '<i class="fas fa-calendar"></i>',
        class: "event"
      },
      {
        start: "2021-04-27",
        end: "2021-04-27",
        title: "Birthday - John",
        content: '<i class="fas fa-birthday-cake"></i>',
        class: "birthday"
      },
      {
        start: "2021-04-27",
        end: "2021-04-27",
        title: "Leave - Tunde",
        content: '<i class="fas fa-umbrella"></i>',
        class: "leave"
      },
      {
        start: "2021-04-28",
        end: "2021-04-28",
        title: "Leave - Tunde",
        content: '<i class="fas fa-umbrella"></i>',
        class: "leave"
      }
    ],
    all_leave_comments: [],
    leave_comment_dialog: false,
    comment: "",
    add_comment_text: "Submit",
    add_comment_submit: false,
    all_leave_remaining_calc: []
  }),
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    menu2(val) {
      val && setTimeout(() => (this.$refs.picker2.activePicker = "YEAR"));
    }
    // authCompanyAllLeave(new_value) {
    //   let _this = this;
    //   if (new_value) {
    //     _this.authCompanyAllLeave.forEach(lv => {
    //       axios
    //         .post(`${BASE_URL}leaveremain`, {
    //           company_id: _this.authCompanyData.id,
    //           employee_id: lv.employee_id,
    //           // leave_type: lv.leave_type,
    //           leavetypes: [
    //             {
    //               id: lv.leave_type,
    //               year: new Date().getFullYear()
    //             }
    //           ]
    //         })
    //         .then(function(response) {
    //           _this.all_leave_remaining.push({id: lv.id, message: response.data.message});
    //         })
    //         .catch(function() {
    //           _this.all_leave_remaining.push({id: lv.id, message: 'Error in evaluation'});
    //         })
    //         .then(function() {
    //           //
    //         });
    //     });
    //   }
    // }
  },
  // components: { 'vue-cal': vuecal },
  computed: {
    ...mapGetters([
      "validEmployeeId",
      "authCompanyData",
      "userType",
      "checkedIn",
      "authEmployeeDataForCompany",
      "checkInMs",
      "authCompanyAllLeave",
      "authCompanyAllLeaveType",
      "authCompanyApprovedLeave",
      "employee_id"
    ]),
    assets_url() {
      return ASSETS_URL;
    },
    filteredLeaveLeft() {
      let _this = this,
        result = [];
      result = _this.all_leave_remaining_calc.filter((lt) => {
        return lt.employee_id == _this.get_employee_id(_this.employee_name);
      });
      return result;
    },
    filteredLeave() {
      let _this = this,
        leave = [],
        matches = false;
      if (_this.filter == 1) {
        if (
          _this.authCompanyAllLeave &&
          _this.filtering.from &&
          _this.filtering.to
        ) {
          leave = _this.authCompanyAllLeave.filter((l) => {
            matches =
              new Date(l.leave_start).getTime() >=
              new Date(_this.filtering.from).getTime() &&
              new Date(l.leave_start).getTime() <=
              new Date(_this.filtering.to).getTime();
            // (
            //   new Date(l.leave_end).getTime() >= new Date(_this.filtering.from).getTime()
            //   &&
            //   new Date(l.leave_end).getTime() <= new Date(_this.filtering.to).getTime()
            // )
            return matches;
          });
          return leave;
        }
        return _this.authCompanyAllLeave;
      } else if (_this.filter == 2) {
        if (
          _this.authCompanyApprovedLeave &&
          _this.filtering.from &&
          _this.filtering.to
        ) {
          leave = _this.authCompanyApprovedLeave.filter((l) => {
            matches =
              new Date(l.leave_start).getTime() >=
              new Date(_this.filtering.from).getTime() &&
              new Date(l.leave_start).getTime() <=
              new Date(_this.filtering.to).getTime();
            // (
            //   new Date(l.leave_end).getTime() >= new Date(_this.filtering.from).getTime()
            //   &&
            //   new Date(l.leave_end).getTime() <= new Date(_this.filtering.to).getTime()
            // )
            return matches;
          });
          return leave;
        }
        return _this.authCompanyApprovedLeave;
      } else if (_this.filter == 3) {
        if (
          _this.allDisapprovedLeave &&
          _this.filtering.from &&
          _this.filtering.to
        ) {
          leave = _this.allDisapprovedLeave.filter((l) => {
            matches =
              new Date(l.leave_start).getTime() >=
              new Date(_this.filtering.from).getTime() &&
              new Date(l.leave_start).getTime() <=
              new Date(_this.filtering.to).getTime();
            // (
            //   new Date(l.leave_end).getTime() >= new Date(_this.filtering.from).getTime()
            //   &&
            //   new Date(l.leave_end).getTime() <= new Date(_this.filtering.to).getTime()
            // )
            return matches;
          });
          return leave;
        }
        return _this.allDisapprovedLeave;
      } else {
        if (
          _this.allPendingLeave &&
          _this.filtering.from &&
          _this.filtering.to
        ) {
          leave = _this.allPendingLeave.filter((l) => {
            matches =
              new Date(l.leave_start).getTime() >=
              new Date(_this.filtering.from).getTime() &&
              new Date(l.leave_start).getTime() <=
              new Date(_this.filtering.to).getTime();
            // (
            //   new Date(l.leave_end).getTime() >= new Date(_this.filtering.from).getTime()
            //   &&
            //   new Date(l.leave_end).getTime() <= new Date(_this.filtering.to).getTime()
            // )
            return matches;
          });
          return leave;
        }
        return _this.allPendingLeave;
      }
    },
    allDisapprovedLeave() {
      let _this = this;
      return _this.authCompanyAllLeave.filter((leave) => {
        return leave.status == 0;
      });
    },
    allPendingLeave() {
      let _this = this;
      return _this.authCompanyAllLeave.filter((leave) => {
        return leave.status == null;
      });
    }
  },
  methods: {
    ...mapActions([
      "fetch_all_approved_leave",
      "fetch_all_leave",
      "fetch_all_leave_type",
      "fetch_company_workflows",
    ]),
    getLeaveDaysApplied(leave_start, leave_end) {
      const _this = this;
      return _this.getDatesBetweenDates(new Date(leave_start), new Date(leave_end)).length + 1;
    },
    getLeaveDaysLeft(employeeId, leaveTypeId) {
      let _this = this, leaveRemaining = [];
      leaveRemaining = _this.all_leave_remaining_calc.filter(lt => {
        return lt.employee_id == employeeId;
      });
      if (leaveRemaining.length >= 1) {
        let leavePolicy = leaveRemaining.filter(LR => LR.leave_type_id == leaveTypeId);
        if (leavePolicy.length >= 1) {
          return leavePolicy[0].message;
        }
        return 'Leave Policy not found';
      }
      return 'Loading...';
    },
    get_employee_name: function (t) {
      var e = this;
      if (e.mixinBranchEmployees(e.$route.name)) {
        var a = e.mixinBranchEmployees(e.$route.name).filter(function (e) {
          return e.id == t;
        })[0];
        return a.employee_fristname + " " + a.employee_lastname;
      }
      return "No employee";
    },
    get_employee_id(employee_name) {
      let _this = this;
      let employee = _this.mixinBranchEmployees(_this.$route.name).filter(
        (e) => employee_name == e.employee_fristname + " " + e.employee_lastname
      );
      if (employee.length) {
        return employee[0].id;
      } else {
        return null;
      }
    },
    fetch_leave_left(employee_id) {
      let _this = this;
      if (_this.authCompanyAllLeaveType.length >= 1) {
        _this.authCompanyAllLeaveType.forEach((t) => {
          axios
            .post(`${BASE_URL}leaveremain`, {
              company_id: _this.authCompanyData.id,
              employee_id,
              leavetypes: [
                {
                  id: t.id,
                  year: new Date().getFullYear()
                }
              ]
            })
            .then(function (response) {
              let response_message =
                response.data.message.split(" ").length >= 1
                  ? response.data.message.split(" ")[1]
                  : "";
              let days_taken = response_message
                ? (_this.get_type_by_name(t.leave_name)
                  ? _this.get_type_by_name(t.leave_name).leave_duration
                  : 0) - parseInt(response_message)
                : "";
              _this.all_leave_remaining_calc.push({
                id: t.id,
                leave_type_id: t.id,
                employee_id,
                leave_description: t.description,
                message: response.data.message,
                days_taken,
                leave_name: t.leave_name
              });
            })
            .catch(function (e) {
              console.log(e);
              _this.all_leave_remaining_calc.push({
                id: t.id,
                leave_type_id: t.id,
                employee_id,
                leave_description: "Error in evaluation",
                message: "Error in evaluation",
                days_taken: "Error in evaluation",
                leave_name: t.leave_name
              });
            })
            .then(function () {
              //
            });
        });
      }
    },
    hasHistory: function () {
      return window.history.length > 2;
    },
    save: function (t) {
      this.$refs.menu.save(t);
    },
    save2: function (t) {
      this.$refs.menu2.save(t);
    },
    async main_leave_action() {
      let _this = this;
      const Toast = _this.$swal.mixin({
        toast: !0,
        position: "top-end",
        showConfirmButton: !1,
        timer: 2e3,
        timerProgressBar: !0,
        didOpen: function (t) {
          t.addEventListener("mouseenter", _this.$swal.stopTimer),
            t.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });
      _this.add_comment_text = "";
      _this.add_comment_submit = true;

      if (_this.approval) {
        axios
          .post(`${BASE_URL}approveleave`, {
            ids: [
              {
                id: _this.current_leave.id,
                comment: _this.comment
              }
            ]
          })
          .then(function () {
            Toast.fire({
              icon: "success",
              title: "Leave approval successful"
            });
            _this.comment_dialog = false;
            _this.comment = "";
          })
          .catch(function (error) {
            let errors = error.response.data.error || null;
            Toast.fire({
              icon: "error",
              title: errors
                ? typeof errors === "string"
                  ? errors
                  : null || errors.comment
                    ? errors.comment[0]
                    : null
                : null ||
                error.response.data.error ||
                error.response.data.message ||
                "Leave approval failed"
            });
            console.log(error);
          })
          .then(function () {
            _this.fetch_all_leave();
            _this.fetch_all_approved_leave();
            _this.add_comment_submit = false;
            _this.add_comment_text = "Submit";
          });
      } else {
        axios
          .post(`${BASE_URL}disapproveleave`, {
            ids: [
              {
                id: _this.current_leave.id,
                comment: _this.comment
              }
            ]
          })
          .then(function () {
            Toast.fire({
              icon: "success",
              title: "Leave rejection successful"
            });
            _this.comment_dialog = false;
            _this.comment = "";
          })
          .catch(function (error) {
            let errors = error.response.data.error || null;
            Toast.fire({
              icon: "error",
              title: errors
                ? typeof errors === "string"
                  ? errors
                  : null || errors.comment
                    ? errors.comment[0]
                    : null
                : null ||
                error.response.data.error ||
                error.response.data.message ||
                "Leave rejection failed"
            });
            console.log(error);
          })
          .then(function () {
            _this.fetch_all_leave();
            _this.fetch_all_approved_leave();
            _this.add_comment_submit = false;
            _this.add_comment_text = "Submit";
          });
      }
    },
    async submit_leave_comment() {
      let _this = this;
      const valid = await _this.$refs.form2.validate();
      if (!valid) {
        return;
      }
      const Toast = _this.$swal.mixin({
        toast: !0,
        position: "top-end",
        showConfirmButton: !1,
        timer: 2e3,
        timerProgressBar: !0,
        didOpen: function (t) {
          t.addEventListener("mouseenter", _this.$swal.stopTimer),
            t.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });
      _this.add_comment2_text = "";
      _this.add_comment2_submit = true;
      axios
        .post(`${BASE_URL}addleavecomment`, {
          company_id: _this.current_leave.company_id,
          leave_id: _this.current_leave.id,
          comments: _this.comment2,
          employee_id: _this.authEmployeeDataForCompany.id || _this.employee_id
        })
        .then(function () {
          Toast.fire({
            icon: "success",
            title: "Leave comment sent successfully"
          });
          _this.view_comment(_this.current_leave);
          _this.add_comment2_submit = false;
          _this.add_comment2_text = "Submit";
          _this.comment2 = "";
          _this.add_leave_comment_dialog = false;
        })
        .catch(function (error) {
          let errors = error.response.data.error || null;
          Toast.fire({
            icon: "error",
            title: errors
              ? typeof errors === "string"
                ? errors
                : null || errors.comment
                  ? errors.comment[0]
                  : null
              : null ||
              error.response.data.error ||
              error.response.data.message ||
              "Leave comment failed to add"
          });
        })
        .then(function () {
          //
        });
    },
    async update_leave_comment(comment) {
      let _this = this;
      const valid = await _this.$refs.form3.validate();
      if (!valid) {
        return;
      }
      const Toast = _this.$swal.mixin({
        toast: !0,
        position: "top-end",
        showConfirmButton: !1,
        timer: 2e3,
        timerProgressBar: !0,
        didOpen: function (t) {
          t.addEventListener("mouseenter", _this.$swal.stopTimer),
            t.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });
      _this.add_comment3_text = "";
      _this.add_comment3_submit = true;
      axios
        .post(`${BASE_URL}editleavecomment`, {
          id: comment.id,
          comments: _this.comment3
        })
        .then(function () {
          Toast.fire({
            icon: "success",
            title: "Leave comment updated successfully"
          });
          _this.view_comment(_this.current_leave);
          _this.add_comment3_submit = false;
          _this.add_comment3_text = "Update";
          _this.edit_leave_comment_dialog = false;
        })
        .catch(function (error) {
          let errors = error.response.data.error || null;
          Toast.fire({
            icon: "error",
            title: errors
              ? typeof errors === "string"
                ? errors
                : null || errors.comments
                  ? errors.comments[0]
                  : null
              : null ||
              error.response.data.error ||
              error.response.data.message ||
              "Leave comment failed to update"
          });
        })
        .then(function () {
          //
        });
    },
    view_comment(leave) {
      let _this = this;
      _this.current_leave = leave;
      axios
        .post(`${BASE_URL}fetchallcommentbyleaveid`, {
          company_id: leave.company_id,
          leave_id: leave.id
        })
        .then(function (response) {
          _this.all_leave_comments = response.data.comments;
          _this.leave_comment_dialog = true;
        })
        .catch(function () {
          //
        })
        .then(function () {
          //
        });
    },
    refresh_page() {
      let _this = this;
      _this.refreshing_page = true;
      _this.fetch_all_approved_leave();
      _this.fetch_all_leave();
      _this.refreshing_page = false;
    },
    get_type_name(type_id) {
      let _this = this;
      let type = _this.authCompanyAllLeaveType.filter(
        (type) => type.id == type_id
      );
      if (type.length) {
        return type[0].leave_name;
      } else {
        return null;
      }
    },
    get_type_by_name(type_name) {
      let _this = this;
      let type = _this.authCompanyAllLeaveType.filter(
        (type) => type.leave_name == type_name
      );
      if (type.length) {
        return type[0];
      } else {
        return null;
      }
    },
    // fetch_days_left(id) {
    //   let _this = this;
    //   if (_this.all_leave_remaining) {
    //     return _this.all_leave_remaining.filter(lr => {
    //       return lr.id == id
    //     })[0].message
    //   } else {
    //     return 'Error in evaluation';
    //   }
    // },
    delete_comment(comment) {
      let _this = this;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        company_id: _this.authCompanyData.id,
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      _this.$swal
        .fire({
          title: "Are you sure you want to delete this comment?",
          showCancelButton: true,
          confirmButtonColor: "#3085d6 !important",
          confirmButtonText: `Yes, Delete`
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            // _this.$swal.fire(number >= 2 ? 'Appraisals deleted!' : 'Appraisal deleted!', '', 'success')
            axios
              .post(`${BASE_URL}deleteleavecomment`, {
                id: comment.id
              })
              .then(function () {
                Toast.fire({
                  icon: "success",
                  title: "Leave comment deleted successfully"
                });
              })
              .catch(function (error) {
                let errors = error.response.data.error || null;
                Toast.fire({
                  icon: "error",
                  title: errors
                    ? typeof errors === "string"
                      ? errors
                      : null || errors.comment
                        ? errors.comment[0]
                        : null
                    : null ||
                    error.response.data.error ||
                    error.response.data.message ||
                    "Leave comment failed to delete"
                });
              })
              .then(function () {
                _this.view_comment(_this.current_leave);
              });
          }
        });
    },
    approve_single_leave(leave) {
      let _this = this;

      _this.$swal
        .fire({
          title: "Are you sure you want to approve this leave?",
          showCancelButton: !0,
          confirmButtonText: "Yes, Approve"
        })
        .then(function (s) {
          _this.approval = true;
          _this.current_leave = leave;
          s.isConfirmed ? (_this.comment_dialog = true) : "";
        });
    },
    disapprove_single_leave(leave) {
      let _this = this;

      _this.$swal
        .fire({
          title: "Are you sure you want to reject this leave?",
          showCancelButton: !0,
          confirmButtonText: "Yes, Reject"
        })
        .then(function (s) {
          _this.approval = false;
          _this.current_leave = leave;
          s.isConfirmed ? (_this.comment_dialog = true) : "";
        });
    }
  },
  mounted() {
    let _this = this;
    document.title = "Kylian ERP - Absence or Leave";
    _this.fetch_all_approved_leave();
    _this.fetch_all_leave();
    _this.fetch_all_leave_type();
    _this.fetch_company_workflows();

    _this.mixinBranchEmployees(_this.$route.name).forEach(function (e) {
      _this.allEmployees.push(e.employee_fristname + " " + e.employee_lastname);
      _this.fetch_leave_left(e.id);
    });
    _this.employee_name = _this.allEmployees[0];

    if (_this.authCompanyAllLeave.length >= 1) {
      _this.overlay = false;
    } else {
      _this.overlay = false;
    }
  }
};
</script>

<style>
.vuecal__event.leave {
  background-color: rgba(253, 156, 66, 0.9);
  border: 1px solid rgb(233, 136, 46);
  color: #fff;
}

.vuecal__event.birthday {
  background-color: rgba(255, 102, 102, 0.9);
  border: 1px solid rgb(235, 82, 82);
  color: #fff;
}

.vuecal__event.event {
  background-color: rgba(0, 102, 153, 0.849);
  border: 1px solid #069;
  color: #fff;
}
</style>
<style scoped>
.lead {
  font-size: 130%;
}
</style>
