<template>
    <v-col id="payrunBreakdown" cols="12" class="card my-3"
        style="border-radius: 10px;">
        <div class="card-header px-3" style="background: #fff;">
            <v-row>
                <v-col cols="12" class="text-center mb-4">
                    <img :src="authCompanyData.logo
                        ? host_url + authCompanyData.logo
                        : '/img/kylian-erp-logo.png'
                        " alt="" style="max-width: 250px; height: inherit;" />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <h4>Pay Run Breakdown</h4>
                </v-col>
                <v-col cols="12" md="9">
                    <h6 class="mt-2 mb-2">
                        <span style="font-weight: bold;">Start Date:</span>
                        {{ approvedPayrollBreakdownData.payslipData.totals.fromDate }}.
                        <span style="font-weight: bold;">End Date:</span>
                        {{ approvedPayrollBreakdownData.payslipData.totals.toDate }}.
                        <span style="font-weight: bold;">Payment Date:</span>
                        {{ approvedPayrollBreakdownData.payslipData.totals.paymentDate }}.
                    </h6>
                    <div v-if="approvedPayrollBreakdownData.isTaxCompliant && actualHeaders.length >= 1">
                        <h6 class="mb-2" style="font-weight: bold;">
                            Employer's Total Statutory Contributions: ₦{{ approvedPayrollBreakdownData.payslipData.totals.employerStatutoryDeductionsAmount | number_with_commas }}.
                        </h6>
                        <h6 class="mb-2" style="font-weight: bold;">
                            Employee's Total Statutory Contributions: ₦{{ approvedPayrollBreakdownData.payslipData.totals.employeeStatutoryDeductionsAmount | number_with_commas }}.
                        </h6>
                        <h6 class="mb-2" style="font-weight: bold;" v-for="(employeeStatutoryDeduction, employeeStatutoryDeductionIndex) in approvedPayrollBreakdownData.payslipData.totals.payslipStatutoryDeductions" :key="`total-statutory-deduction-${employeeStatutoryDeductionIndex}`">
                            {{ employeeStatutoryDeduction.deduction }}: ₦{{ employeeStatutoryDeduction.amount | number_with_commas }}.
                        </h6>
                    </div>
                </v-col>
                <v-col  cols="12" md="3">
                    <h3 class="mt-2 text-left text-md-right">
                        {{ authCompanyData.company_name }}
                    </h3>
                </v-col>
            </v-row>
        </div>
        <div class="card-body px-1 py-1 pt-0 pr-0">
            <div :style="$vuetify.breakpoint.name == 'xs' ||
                $vuetify.breakpoint.name == 'sm'
                ? '/*height: 30vh;*/'
                : '/*height: 30vh;*/'
                "
                style="border-radius: 10px; padding-top: 0; padding-bottom: 0;">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="text-left" style="border-top: none;">
                                S/N
                            </th>
                            <th class="text-left" style="border-top: none;"
                                scope="col">
                                Employee
                            </th>
                            <th class="text-left" style="border-top: none;"
                                scope="col">
                                Department
                            </th>
                            <th class="text-left" style="border-top: none;"
                                scope="col">
                                Basic Salary
                            </th>
                            <th class="text-left" style="border-top: none;"
                                scope="col">
                                Total Allowances
                            </th>
                            <th v-for="(header, headerIndex) in actualHeaders"
                                :key="`header-${headerIndex}`" class="text-left"
                                style="border-top: none;" scope="col">
                                {{ header }}
                            </th>
                            <th v-if="approvedPayrollBreakdownData.isTaxCompliant" class="text-left" style="border-top: none;"
                                scope="col">
                                Total Statutory Deductions
                            </th>
                            <th class="text-left" style="border-top: none;"
                                scope="col">
                                Total {{ approvedPayrollBreakdownData.isTaxCompliant ? 'Non-Statutory ' : '' }} Deductions
                            </th>
                            <th class="text-left" style="border-top: none;"
                                scope="col">
                                Net Pay
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(employeePayData, employeePayDataIndex) in approvedPayrollBreakdownData.payslipData.employeePayslipsData" :key="'employee-payslip-data-' + employeePayDataIndex">
                            <td class="text-left">{{ employeePayDataIndex + 1 }}</td>
                            <td class="text-left">
                                {{ employeePayData.employeePayslip.employeeName }}
                            </td>
                            <td class="text-left">
                                {{ employeePayData.employeePayslip.department }}
                            </td>
                            <td class="text-left">
                                ₦{{ employeePayData.employeePayslip.salaryAmount | number_with_commas }}
                            </td>
                            <td class="text-left">
                                ₦{{ employeePayData.employeePayslip.allowancesAmount | number_with_commas }}
                            </td>
                            <td v-for="(employeeStatutoryDeduction, employeeStatutoryDeductionIndex) in employeePayData.employeePayslipStatutoryDeductions" :key="`statutory-deduction-${employeeStatutoryDeductionIndex}`" class="text-left">
                                ₦{{ employeeStatutoryDeduction.amount | number_with_commas }}
                            </td>
                            <td v-if="approvedPayrollBreakdownData.isTaxCompliant" class="text-left">
                                ₦{{ employeePayData.employeePayslip.statutoryDeductionsAmount | number_with_commas }}
                            </td>
                            <td class="text-left">
                                ₦{{ employeePayData.employeePayslip.nonStatutoryDeductionsAmount | number_with_commas }}
                            </td>
                            <td class="text-left">
                                ₦{{ employeePayData.employeePayslip.netPayAmount | number_with_commas }}
                            </td>
                        </tr>
                        <tr style="border-top: 1px solid #ddd;">
                            <th class="text-left" style="border-top: none;"></th>
                            <th class="text-left" style="border-top: none;"
                                scope="col"></th>
                            <th class="text-left" style="border-top: none;"
                                scope="col"></th>
                            <th class="text-left" style="border-top: none;"
                                scope="col">
                                Basic Salary
                            </th>
                            <th class="text-left" style="border-top: none;"
                                scope="col">
                                Total Allowances
                            </th>
                            <th v-for="(header, headerIndex) in actualHeaders"
                                :key="`header-${headerIndex}`" class="text-left"
                                style="border-top: none;" scope="col">
                                {{ header }}
                            </th>
                            <th v-if="approvedPayrollBreakdownData.isTaxCompliant" class="text-left" style="border-top: none;"
                                scope="col">
                                Total Statutory Deductions
                            </th>
                            <th class="text-left" style="border-top: none;"
                                scope="col">
                                Total {{ approvedPayrollBreakdownData.isTaxCompliant ? 'Non-Statutory ' : '' }} Deductions
                            </th>
                            <th class="text-left" style="border-top: none;"
                                scope="col">
                                Net Pay
                            </th>
                        </tr>
                        <tr>
                            <th class="text-left"></th>
                            <th class="text-left"></th>
                            <th class="text-left">TOTAL</th>
                            <th class="text-left">
                                ₦{{ approvedPayrollBreakdownData.payslipData.totals.salaryAmount | number_with_commas }}
                            </th>
                            <th class="text-left">
                                ₦{{ approvedPayrollBreakdownData.payslipData.totals.allowancesAmount | number_with_commas }}
                            </th>
                            <template v-if="actualHeaders.length >= 1">
                                <th v-for="(employeeStatutoryDeduction, employeeStatutoryDeductionIndex) in approvedPayrollBreakdownData.payslipData.totals.payslipStatutoryDeductions" :key="`total-statutory-deduction-${employeeStatutoryDeductionIndex}`" class="text-left">
                                    ₦{{ employeeStatutoryDeduction.amount | number_with_commas }}
                                </th>
                            </template>
                            <th v-if="approvedPayrollBreakdownData.isTaxCompliant" class="text-left">
                                ₦{{ approvedPayrollBreakdownData.payslipData.totals.statutoryDeductionsAmount | number_with_commas }}
                            </th>
                            <th class="text-left">
                                ₦{{ approvedPayrollBreakdownData.payslipData.totals.nonStatutoryDeductionsAmount | number_with_commas }}
                            </th>
                            <th class="text-left">
                                ₦{{ approvedPayrollBreakdownData.payslipData.totals.netPayAmount | number_with_commas }}
                            </th>
                        </tr>
                    </tbody>
                </table>
                <div v-if="showSignature" class="text-left">
                    <h4 class="pt-5">
                        Signature: ____________________
                    </h4>
                </div>
            </div>
        </div>
    </v-col>
</template>

<script>
    export default {
        props: {
            authCompanyData: Object,
            host_url: String,
            approvedPayrollBreakdownData: Object,
            showSignature: Boolean
        },
        computed: {
            actualHeaders() {
                const _this = this;
                let headers = [];
                if (_this.approvedPayrollBreakdownData) {
                    if (_this.approvedPayrollBreakdownData.payslipData.employeePayslipsData.length >= 1) {
                        headers = _this.approvedPayrollBreakdownData.payslipData.employeePayslipsData[0].employeePayslipStatutoryDeductions.map(statutoryDeduction => statutoryDeduction.deduction);
                    }
                }
                return headers;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>