<template>
  <div
    class="tab-pane fade"
    id="list-settings501"
    role="tabpanel"
    aria-labelledby="list-settings-list501"
  >
    <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
      <div class="card-header">
        Salary Ratio
      </div>
      <div class="row card-body text-center py-3 mt-0">
        <p class="col-12 text-left py-1 mb-1">
          <strong>Percentage Distribution: </strong>
          <span v-if="authCompanyEmolumentSetting">
            {{ authCompanyEmolumentSetting.percentage_distribution + "%" }}
          </span>
          <i v-else>100%</i>
        </p>
        <p class="col-md-6 text-left py-1 mb-1">
          <strong>Status: </strong>
          <span
            v-if="
              authCompanyEmolumentSetting
                ? authCompanyEmolumentSetting.status == 1
                : null
            "
            class="badge text-center badge-success"
            >ACTIVE</span
          >
          <span v-else class="badge text-center badge-warning">INACTIVE</span>
        </p>
        <p class="col-md-6 text-right py-1 mb-1">
          <label class="switch switch-secondary mr-3">
            <span v-if="formData.status">Enabled</span>
            <span v-else>Disabled</span>
            <input
              @change="switchEmolumentSettingStatus"
              type="checkbox"
              :checked="formData.status"
              :disabled="switchingEmolumentSettingStatus"
              v-model="formData.status"
            />
            <span class="slider"></span>
          </label>
        </p>
      </div>
      <div
        v-if="permissionIsAssigned('payroll-approve')"
        class="card-footer py-1 text-right row"
      >
        <div class="col-lg-6 offset-lg-6 py-0 pb-3 px-0">
          <button
            @click="launchEmolumentSettingUpdate"
            type="button"
            class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple"
          >
            Update
          </button>
        </div>
      </div>
    </div>
    <v-dialog v-model="emolumentSettingDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Edit Salary Ratio</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent>
              <div class="container-fluid">
                <v-row>
                  <v-col class="pb-0" cols="12">
                    <span style="color: #111d5e;"
                      >Percentage Distribution
                      <i
                        class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                      ></i
                    ></span>
                    <v-text-field
                      v-model="formData.percentage_distribution"
                      placeholder="Enter percentage distribution"
                      type="number"
                      solo
                      class="mt-2"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                    offset-md="4"
                    offset-sm="4"
                    class="remove-hint"
                  >
                    <button
                      @click="
                        formData.emolumentSettingLoading
                          ? null
                          : updateEmolumentSetting()
                      "
                      style="width: 99%;"
                      type="button"
                      class="btn btn-secondary m-1 text-white btn-raised ripple"
                    >
                      {{ formData.emolumentSettingText }}
                      <v-icon
                        :style="
                          !formData.emolumentSettingLoading
                            ? 'display: none;'
                            : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                        "
                      >
                        fas fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="emolumentSettingDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import axios from "axios";
  import { BASE_URL } from "@/main";
  import { mapActions, mapState, mapGetters } from "vuex";
  export default {
    props: {},
    data() {
      return {
        formData: {
          percentage_distribution: 100,
          status: false,
          emolumentSettingText: "Update",
          emolumentSettingLoading: false,
        },
        emolumentSettingDialog: false,
        switchingEmolumentSettingStatus: false,
      };
    },
    computed: {
      ...mapState(["userToken"]),
      ...mapGetters([
        "authCompanyData",
        "authCompanyEmolumentSetting",
        "validEmployeeId",
      ]),
    },
    methods: {
      ...mapActions(["fetchCompanyEmolumentSetting"]),
      launchEmolumentSettingUpdate() {
        const _this = this;
        if (_this.authCompanyEmolumentSetting) {
          _this.formData.percentage_distribution =
            _this.authCompanyEmolumentSetting.percentage_distribution;
          _this.formData.status = _this.authCompanyEmolumentSetting.status;
        }
        _this.emolumentSettingDialog = true;
      },
      switchEmolumentSettingStatus() {
        let _this = this;
        _this.switchingEmolumentSettingStatus = true;
        const Toast = _this.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", _this.$swal.stopTimer);
            toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
          },
        });
        axios
          .post(
            `${BASE_URL}updateemolumentstatus`,
            {
              emolumentsetting_id: _this.authCompanyEmolumentSetting.id,
            },
            {
              headers: {
                Authorization: `Bearer ${_this.userToken}`,
              },
            }
          )
          .then(function() {
            Toast.fire({
              icon: "success",
              title: "Salary ratio status updated successfully",
            });
            _this.fetchCompanyEmolumentSetting().then(() => {
              _this.allowanceFormData.percentage_distribution =
                _this.authCompanyEmolumentSetting.percentage_distribution;
              _this.allowanceFormData.status =
                _this.authCompanyEmolumentSetting.status;
            });
            _this.switchingEmolumentSettingStatus = false;
          })
          .catch(function(error) {
            let errors = error.response.data.error || null;
            Toast.fire({
              icon: "error",
              title: errors
                ? typeof errors === "string"
                  ? errors
                  : null
                : null ||
                  error.response.data.error ||
                  error.response.data.message ||
                  "Salary ratio status couldn't be updated",
            });
          })
          .then(function() {
            _this.switchingEmolumentSettingStatus = false;
          });
      },
      updateEmolumentSetting() {
        let _this = this;
        _this.formData.emolumentSettingText = "";
        _this.formData.emolumentSettingLoading = true;
        const Toast = _this.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", _this.$swal.stopTimer);
            toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
          },
        });
        axios
          .post(
            `${BASE_URL}${
              _this.authCompanyEmolumentSetting
                ? "updateemolumentsetting"
                : "createemolumentowsetting"
            }`,
            {
              percentage_distribution: _this.formData.percentage_distribution,
              company_id: _this.authCompanyData.id,
              employee_id: _this.validEmployeeId,
              ...(_this.authCompanyEmolumentSetting
                ? { emolumentsetting_id: _this.authCompanyEmolumentSetting.id }
                : {}),
            },
            {
              headers: {
                Authorization: `Bearer ${_this.userToken}`,
              },
            }
          )
          .then(function() {
            Toast.fire({
              icon: "success",
              title: "Salary ratio updated successfully",
            });
            _this.emolumentSettingDialog = false;
            _this.formData.percentage_distribution = 100;
            _this.formData.status = false;
            _this.fetchCompanyEmolumentSetting();
          })
          .catch(function(error) {
            let errors = error.response.data.error || null;
            Toast.fire({
              icon: "error",
              title: errors
                ? typeof errors === "string"
                  ? errors
                  : null
                : null ||
                  error.response.data.error ||
                  error.response.data.message ||
                  "Salary ratio couldn't be updated",
            });
          })
          .then(function() {
            _this.formData.emolumentSettingLoading = false;
            _this.formData.emolumentSettingText = "Update";
          });
      },
    },
    mounted() {
      const _this = this;
      _this.fetchCompanyEmolumentSetting();
      if (_this.authCompanyEmolumentSetting) {
        _this.formData.status = _this.authCompanyEmolumentSetting.status;
      }
    },
  };
</script>

<style></style>
